import Vue from 'vue'

// 自定义全局的按钮防抖函数，一秒内点击多次只执行一次
Vue.directive('btnDebounce', {
  inserted(el, binding) {
    let timer = {}
    el.addEventListener('click', () => {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        binding.value()
      }, 1000)
    })
  }
})


Vue.directive('hasPermi', {
  inserted(el, binding) {
    let roleCode = window.sessionStorage.getItem('roleCodes')
    if (!roleCode) {
      el.parentNode && elparentNode.removeChild(el)
      return
    }
    let roles = roleCode.split(',')
    const { value } = binding
    if (value && value instanceof Array) {
      if (value.length > 0) {
        const permissionRoles = value
        const hasPermission = roles.some(role => {
          return permissionRoles.includes(role)
        })

        if (!hasPermission) {
          el.parentNode && el.parentNode.removechild(el)
        }
      }
    } else { }

  }
})